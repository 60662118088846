@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap");

:root {
  --brandColor: #bf0d3e;
  --arrow: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMjcuMyAxMjcuMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTI3LjMgMTI3LjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnIGlkPSJ1cF9hcnJvdyI+DQoJPHBvbHlnb24gcG9pbnRzPSI2My43LDU0LjkgODIuOCw3NC4xIDEwNCw3NC4xIDYzLjcsMzMuNyAyMy4zLDc0LjEgNDQuNSw3NC4xIAkiLz4NCjwvZz4NCjwvc3ZnPg0K");
  --gft-bg-color: #212121;
}

/* @media only screen and (max-device-width: 768px) {
  body {
    text-size-adjust: none !important;
    -webkit-text-size-adjust: none !important;
    -moz-text-size-adjust: none !important;
    -ms-text-size-adjust: none !important;
  }
} */

html,
body {
  height: 100%;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: var(--gft-bg-color);
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative !important;
}
#webCheckin {
  height: 100%;
}

/* .h-100 {
  height: unset !important;
} */

/* Potential Fix for Mobile Keyboards shoving screen */
html,
body,
#root,
.App {
  position: fixed;
}

@font-face {
  font-family: big-noodle;
  src: url("../public/fonts/big_noodle_titling.woff") format("woff");
}
@font-face {
  font-family: "Rubik";
  src: url("../public/fonts/Rubik.woff") format("woff");
}
.needsclick {
  display: none !important;
}
.brand_logo img {
  max-width: 100%;
}

.bg-darkGrey {
  background-color: #212121;
  border-radius: 0px 10px 0px 0px;
}
.bg-lightGrey {
  background-color: #333;
  border-radius: 0px 0px 10px 0px;
}

.bg-tg-red-subdued-upper {
  background-color: #3f3f3fc9;
  border-radius: 0px 10px 0px 0px;
}

.bg-tg-red-subdued-lower {
  background-color: #3f3f3fc9;
  border-radius: 0px 0px 10px 0px;
}

.gft-embed span,
.gft-embed button,
.gft-embed label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.index-1 {
  z-index: 1;
}

/* Headings */
h2 {
  font-family: big-noodle, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2.6875rem;
  text-transform: uppercase;
  line-height: 1.5;
  display: block;
  letter-spacing: 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h3 {
  font-family: "big-noodle", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 2.375rem;
  text-transform: uppercase;
  line-height: 1.5;
  color: #151515;
  display: block;
  letter-spacing: 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media only screen and (max-width: 798px) {
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 30px;
  }
}
h6 {
  font-family: big-noodle, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.75rem;
  text-transform: uppercase;
  line-height: 1.5;
  display: block;
  letter-spacing: 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Icons */
.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.2rem;
  width: 1.2rem;
  fill: currentColor;
}

/* Arrows */
.arrowButton {
  position: relative;
  background: var(--brandColor);
  border: none;
  color: #fff;
  text-transform: uppercase;
  line-height: 75%;
  font-size: 2.5rem;
  font-family: big-noodle;
  font-weight: 400;
  font-style: normal;
  padding: 5px 40px 5px 15px;
  max-width: fit-content;
  white-space: nowrap;
  min-height: 45px;
}
.arrowButton:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(90deg);
  width: 50px;
  height: 50px;
  background-image: var(--arrow);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  filter: brightness(0) invert(1);
}
.arrowReversed {
  padding: 5px 15px 5px 40px;
}
.arrowReversed:before {
  right: unset;
  left: 0;
  transform: translateY(-50%) rotate(-90deg);
}

.arrowHide {
  padding: 5px 15px 5px 15px;
}
.arrowHide:before {
  content: none;
  display: none;
  padding: 0;
}

/* Section Overflow Wrap */

.gft-embed .tab-content {
  overflow: hidden;
  flex: 1;
}
@media screen and (max-width: 768px) {
  .gft-embed .tab-content {
    /* margin-bottom: 77px; */
    margin-bottom: 65px;
  }
  .gft-form {
    flex: unset;
  }
}
/* GFT Scrollbar */
.gft-embed *::-webkit-scrollbar {
  width: 4px;
}
.gft-embed *::-webkit-scrollbar-track {
  background: #00000000;
}
.gft-embed *::-webkit-scrollbar-thumb {
  background-color: var(--brandColor);
  border-radius: 0;
  border: 3px solid #00000000;
}
/* Firefox */
.gft-embed * {
  scrollbar-width: auto;
  scrollbar-color: var(--brandColor);
}

@media screen and (min-width: 768px) {
  .gft-embed .container-xl {
    max-width: 1700px;
  }
}
.gft-embed {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;
  color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: var(--gft-bg-color);
  position: relative;
  color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.gft-embed:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10px;
  background: url("../public/imgs/gft_visual_interest_splash.webp");
  background-repeat: no-repeat;
  background-size: contain;
  width: 20vw;
  min-width: 33vw;
  height: 10vw;
  min-height: 10vw;
  transform: scale(-1);
}
.gft-embed p {
  color: #dfdfdf;
}
.gft-embed li,
.gft-embed h1,
.gft-embed h2,
.gft-embed h3,
.gft-embed h4,
.gft-embed h5,
.gft-embed h6 {
  line-height: 80%;
  color: #fff;
}
.gft-embed .arrowReversed {
  background: transparent;
}
.gft-content {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .gft-content {
    justify-content: normal;
  }
}
@media screen and (max-width: 991px) {
  .gft-embed:before {
    content: none;
  }
}

.gft-view-heading span {
  /* border-bottom: 1px solid var(--brandColor); */
  border-bottom: 1px solid #fff;
}
.gft-view-desc {
  font-size: 1rem;
  max-width: 575px;
}
.gft-view-desc b {
  text-transform: capitalize;
  color: #fff;
}
.gft-content {
  overflow-x: hidden;
  overflow-y: auto;
}
.gft-content .gft-form {
  position: relative;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}
@media screen and (min-width: 768px) {
  .gft-content {
    overflow-x: unset;
    overflow-y: unset;
  }
  .gft-content .gft-form {
    max-height: 500px;
    padding-right: 15px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
#nav-gft_embed_check-in form {
  overflow: unset;
}

.loading-text {
  height: 50%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center !important;
}

/* .scroll-shadow-top, .scroll-shadow-btm {
  transition: 2s ease-in-out;
  position: relative;
}
#scroll-shadow:after, #scroll-shadow:before {
  content: '';
  position: sticky;
  left: 0;
  width: calc(100% + 6px);
  height: 0px;
  display: block;
  transition: .3s ease-in-out;
}
.scroll-shadow-btm:after,
.scroll-shadow-top:before {
  content: '';
  position: sticky;
  left: 0;
  width: calc(100% + 6px);
  height: 25px !important;
  display: flex;
  pointer-events: none;
  z-index: 1;
  opacity: 1;
}
.scroll-shadow-btm:after {
  margin-top: -25px;
  bottom: -1px;
  background: linear-gradient(180deg,hsla(0,0%,100%,0),var(--gft-bg-color)), linear-gradient(180deg, hsla(0, 0%, 100%, 0), var(--gft-bg-color));
}
.scroll-shadow-top:before {
  margin-top: -25px;
  top: -1px;
  background: linear-gradient(0deg,hsla(0,0%,100%,0),var(--gft-bg-color)), linear-gradient(0deg, hsla(0, 0%, 100%, 0), var(--gft-bg-color));
} */

.gft-scroll-up,
.gft-scroll-down {
  opacity: 0;
  visibility: hidden;
}
.gft-scroll-show {
  opacity: 1;
  visibility: visible;
}
.gft-gradient.gft-scroll-up {
  top: 0;
  transform: scaleY(-100%);
}
.gft-gradient span.icon {
  height: 4rem;
  width: 4rem;
}
.gft-gradient.gft-scroll-up .icon,
.gft-gradient.gft-scroll-down .icon {
  pointer-events: all;
  cursor: pointer;
}
/* .gft-gradient.gft-scroll-up span.icon {} */
.gft-dots {
  width: 100%;
  height: 100%;
  position: absolute;
}
.gft-dots:after,
.gft-dots:before {
  content: "";
  position: absolute;
  background: url("../public/imgs/gft_visual_interest_dots.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 3vw;
  min-height: 55px;
}
.gft-dots:after {
  bottom: 5px;
  left: 70px;
}
.gft-dots:before {
  top: 5px;
  right: 70px;
  transform: scale(-1, -1);
}
@media screen and (max-width: 768px) {
  .gft-dots:after,
  .gft-dots:before {
    min-height: 40px;
  }
}

.gft-embed li {
  display: flex;
  align-items: center;
}
.gft-icon {
  height: 15px;
  width: 15px;
  object-fit: contain;
  filter: brightness(0) invert(1);
}
body,
.gft-timeline,
.gft-footer {
  touch-action: none;
}
.gft-info {
  border: 1px solid #444;
  border-radius: 10px;
  background: #222;
}
.gft-info li {
  flex-direction: column;
  align-items: flex-start;
}
.gft-info h6 {
  font-family: Rubik;
  font-weight: 600;
  font-size: 0.85rem;
  letter-spacing: 2px;
  color: #dfdfdf;
}
.gft-info p {
  color: #dfdfdf;
  margin-top: 10px;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  .gft-info div.shopOpening {
    padding-top: 10px !important;
    margin-top: 10px !important;
    border: 0px solid #444 !important;
    border-top-width: 1px !important;
  }
  .gft-info {
    width: 100%;
    padding: 11px 0px;
    background: none;
    border: none;
  }
  .gft-icon {
    height: 12px;
    width: 12px;
  }
  .gft-info h6 {
    font-size: 13px;
    letter-spacing: 0px;
    color: #fff;
    text-align: right;
  }
  span.small-text {
    font-size: 0.75rem;
    color: #000;
  }

  .gft-info p,
  .small-text {
    font-size: 0.65rem;
    margin-top: 6px;
    color: #fff;
  }
}

.badge.text-bg-light.rounded.text-uppercase.btn:hover {
  background-color: var(--brandColor) !important;
  color: #fff !important;
}

.badge.text-bg-light.rounded.text-uppercase.btn:hover span {
  color: #fff !important;
}

.small-text {
  line-height: 80%;
  margin-top: 0;
}

/* GFT Breadcrumbs */

.gft-breadcrumbs * {
  transition: 0.3s ease-out;
}
.gft-breadcrumbs {
  position: relative;
  border: 0;
  justify-content: space-evenly;
  counter-reset: section;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}
.gft-breadcrumbs:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background: #444;
  height: 80%;
  width: 2px;
  transform: translate(0, -50%);
}
.gft-breadcrumbs button,
.gft-breadcrumbs button:hover,
.gft-breadcrumbs button:active,
.gft-breadcrumbs button:focus {
  position: relative;
  background: none !important;
  border: none !important;
  outline: none;
  box-shadow: none;
  color: #fff;
}
.gft-breadcrumbs button.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
  font-size: 1.1rem;
  margin-bottom: 25px;
}
.gft-breadcrumbs button.nav-link:last-of-type {
  margin-bottom: 0;
}
.gft-breadcrumbs button.nav-link span {
  order: 2;
  margin-left: 35px;
  color: #ababab;
  text-align: left;
}
.gft-breadcrumbs button.nav-link span.crumb-mobile-text {
  display: none;
}
.gft-breadcrumbs button.nav-link div {
  position: absolute;
  top: 50%;
  left: -12px;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  padding: 3px;
  border: 2px solid #444;
  outline: 8px solid #212121;
  color: #000;
  transform: translate(0, -50%);
}
/* Breadcrumbs Hover */
.gft-breadcrumbs button.nav-link:hover div {
  border: 2px solid transparent;
  outline: 8px solid #fff;
}
/* Breadcrumbs Active */
.gft-breadcrumbs button.nav-link.active div {
  color: #fff;
  border: 2px solid transparent;
  outline: 8px solid var(--brandColor);
}
.gft-breadcrumbs button.nav-link:hover span,
.gft-breadcrumbs button.nav-link.active span {
  color: #fff;
}
.gft-breadcrumbs button.nav-link.active {
  color: #fff;
}
@media screen and (max-width: 991px) {
  .gft-breadcrumbs {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
  }
  .gft-breadcrumbs:before {
    top: 50%;
    left: 50%;
    height: 2px;
    width: 90%;
    transform: translate(-50%, -50%);
  }
  .gft-breadcrumbs button.nav-link {
    flex-direction: column;
    align-items: center;
  }
  .gft-breadcrumbs button.nav-link span {
    display: none;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    margin-left: 0;
    font-size: 0.6rem;
    z-index: 10;
  }
  .gft-breadcrumbs button.nav-link span.crumb-mobile-text {
    display: block;
  }
  .gft-breadcrumbs button.nav-link {
    margin: 0;
  }
  .gft-breadcrumbs button.nav-link div {
    position: relative;
    left: 0;
    height: 20px;
    width: 20px;
    outline: 9px solid #212121;
    top: 0;
    transform: translateY(0);
  }
}

.gft-embed input.gft-phone-input-enable {
  height: 45px;
  min-width: 50%;
  width: 100%;
  font-size: 1.2rem;
  border-radius: 2px;
}

.gft-embed span.country-name {
  color: #000 !important;
}

.gft-embed input:not(.otp):not(.gft-phone-input-enable) {
  height: 45px;
  min-width: 50%;
  max-width: 100%;
  font-size: 1.2rem;
  padding: 0 10px;
}

.gft-embed input::placeholder {
  opacity: 0.5;
}

.gft-embed label {
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Rubik";
  text-transform: uppercase;
}

.input-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.input-wrap:first-of-type {
  margin-top: 0;
}

.gft-buttons {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.gft-buttons button {
  width: 100%;
  border: 0;
}
@media screen and (max-width: 991px) {
  .gft-footer:not(.position-relative) {
    position: fixed;
    bottom: 40px;
    left: 0px;
    left: 0;
    width: 100%;
    background: #212121;
    max-height: 77px;
  }
  .gft-buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .gft-buttons button {
    width: calc(80% + 10px);
    margin: 0 auto 0 auto !important;
  }
}
@media screen and (min-width: 991px) {
  .gft-buttons button {
    width: fit-content;
    border: 0;
  }
}

@media screen and (max-width: 768px) {
  .gft-embed .arrowButton {
    font-size: 1.7rem;
    min-height: unset;
    padding: 7px 30px 7px 15px;
  }
  .gft-embed .arrowReversed {
    padding: 7px 15px 7px 30px;
  }
  .arrowButton:before {
    width: 30px;
    height: 30px;
  }
}

/* Radio Buttons */
.gft-embed [type="checkbox"]:checked,
.gft-embed [type="checkbox"]:not(:checked),
.gtf-embed [type="radio"]:checked,
.gft-embed [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  width: 0;
  height: 0;
  visibility: hidden;
}
/* Check In View */

/* Guest and Services View */

.gft-guest-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(93.75px, 131.25px));
  grid-row-gap: 25px;
  grid-column-gap: 15px;
  padding-bottom: 25px;
}

/* Inputs */
.gft-embed .gft-input + label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* aspect-ratio: 1; */
  background: #fff;
  border-radius: 5px;
  color: #000;
  padding: 5px;
  border: 2px solid transparent;
  transition: 0.2s ease all;
  cursor: pointer;
  width: 100%;
}
.gft-embed .gft-input:not(:checked):not(:disabled) + label:hover:not(.guest-add) {
  background: #ffe5e5;
  color: #bf0d3e;
}
.gft-embed .gft-input + label span:not(.icon) {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gft-embed .gft-input:checked + label {
  background: var(--brandColor);
  color: #fff;
}

.gft-embed input.gft-input[disabled] + label .gft-service-desc h4 p {
  background-color: #fff !important;
  color: #333 !important;
  font-size: 0.75em !important;
}
.gft-embed .gft-input[disabled] + label,
.gft-embed .gft-input[disabled] + label:hover {
  /* background-color: #a3a3a3; */
  background-color: #fff;
}
.gft-embed .gft-input[disabled] + label:after {
  content: none;
}

@media screen and (max-width: 756px) {
  .gft-embed .gft-input:not(:checked):not(:disabled) + label:hover:not(.guest-add) {
    background: #fff;
  }
}

/* Input Error */

.gft_error {
  margin-top: 10px;
  background: var(--brandColor);
  width: fit-content;
  padding: 7px 10px;
  position: relative;
}
.gft_error:before {
  content: "";
  position: absolute;
  top: -7px;
  left: 15px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--brandColor);
}
.gft_error.no-arrow:before {
  content: none;
}

/* GFT Spinner */

.gft-embed .btn-primary {
  transition: 0.3s ease all !important;
  opacity: 1 !important;
}
.gft-embed .btn-primary:disabled {
  opacity: 0.35 !important;
}
.gft-embed .gft-spinner:not(.show-spinner).btn-primary:after,
.gft-embed .gft-spinner.show-spinner.btn-primary:before {
  content: none;
  display: none;
}
.show-spinner {
  padding-right: 1rem !important;
}

.gft-embed .gft-spinner.btn-primary:after {
  content: "";
  position: relative !important;
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
  border: 5px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite gft-spinner;
  animation: 0.75s linear infinite gft-spinner;
}

@keyframes gft-spinner {
  0% {
    transform: translateY(0%) rotate(0deg);
  }
  100% {
    transform: translateY(0%) rotate(360deg);
  }
}

/* Service Input Label */
.gft-embed .gft-input:checked + label.gft-service,
.gft-embed .gft-input:not(:checked) + label.gft-service {
  aspect-ratio: unset;
  flex-direction: row;
}
.gft-embed .gft-input + label.gft-service:after {
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  left: -15px;
}
.gft-embed .gft-input:checked + label.gft-service:after {
  transform: translateY(-50%) rotate(180deg);
}

/* Guest Plus Minus */
.gft-input + label:after {
  content: "+";
  position: absolute;
  bottom: -15px;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  box-shadow: 0 2px 5px 3px #0000005e;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #fff;
  background: var(--brandColor);
  font-family: "Rubik";
  transition: 0.3s ease;
}
.gft-input:checked + label:after,
.gft-input.remove:not(:checked) + label:after {
  content: "-";
  color: #000;
  background: #fff;
  transform: rotate(180deg);
}
.gft-input + label.guest-add:after {
  content: none;
}
/* Guest Mange */

.manage-guests {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  min-height: max-content !important;
}
.manage-guets .icon {
  height: 1.5rem;
  width: 1.5rem;
}

/* .manage-guests.manage-guests-active {
  color: var(--brandColor) !important;
} */
.guest-edit {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Rubik";
  font-size: 0.7rem;
  color: #000;
  opacity: 0;
  transition: 0.2s ease;
}
/* .gft-input:checked + label {} */
.gft-input + label:hover .guest-edit {
  color: #000;
  opacity: 1;
}
.gft-input + label:hover .guest-edit .icon {
  fill: #000;
}
.gft-input:checked + label:hover .guest-edit {
  color: #fff;
  opacity: 1;
}
.gft-input:checked + label:hover .guest-edit .icon {
  fill: #fff;
}
/* Guest Add */
.gft-input + label.guest-add * {
  transition: all 200ms linear;
}
.gft-input + label.guest-add {
  background: transparent;
  border: 2px dashed #fff;
  color: #fff;
}
.gft-input + label.guest-add span:not(.icon) {
  opacity: 0;
}
.gft-input + label.guest-add:hover span {
  opacity: 1;
}
.gft-input + label.guest-add .icon {
  margin-bottom: -20px;
}
.gft-input + label.guest-add:hover .icon {
  margin-bottom: 0;
  width: 3.5rem;
  height: 3.5rem;
}
.gft-input + label.guest-add .icon {
  height: 3rem;
  width: 3rem;
}

/* Select Service View */
.gft-embed .gft-input + label.gft-service {
  color: #fff;
  margin-bottom: 15px;
  transition: 0.1s ease all;
  padding: 10px 15px 10px 15px;
  display: inline-flex;
  align-items: center;
  max-width: calc(100% - 15px);
  width: 100%;
  margin-left: 15px;
}

.gft-embed .gft-input:checked + label.gft-service {
  margin-left: 20px;
}
.gft-embed .gft-service:last-of-type {
  margin-bottom: 0;
}
.gft-embed .gft-service:hover {
  background: var(--brandColor);
  color: #fff;
}
.gft-embed .gft-service p,
.gft-embed .gft-service h3,
.gft-embed .gft-service h4 {
  color: #000;
}
.gft-embed .gft-input:checked + label.gft-service p,
.gft-embed .gft-input:checked + label.gft-service h3,
.gft-embed .gft-input:checked + label.gft-service h4 {
  color: #fff;
}
.gft-embed .gft-service h4 {
  @include media-breakpoint-down(lg) {
    font-size: rfs-fluid-value(0.5rem);
  }
  margin-bottom: 5px;
  font-family: "Poppins";

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.gft-embed .gft-service p {
  font-size: 0.8rem;
  font-family: "Rubik";
  text-transform: none;
  letter-spacing: normal;
  color: #333;
}
.gft-embed .gft-service-desc {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20px;
}
.gft-embed .gft-service h3 {
  margin-left: 20px;
}

.gft-embed h4.serviceTitle {
  line-height: 1;
}

/* Summary View */
.gft-summary ul {
  border: 1px solid #505050;
  border-radius: 10px;
}
.gft-summary li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 15px;
  color: #eee;
  border-bottom: 1px solid #444;
}
.gft-summary li:nth-of-type(2n + 1) {
  background: #2b2b2b;
}
.gft-summary li:first-of-type {
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.gft-summary li:last-of-type {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}
.gft-summary h5 {
  font-family: "Rubik";
  text-transform: capitalize;
  line-height: unset;
  margin-bottom: 1rem;
}
.gft-summary p {
  color: inherit;
  order: 2;
  font-weight: 400;
}
.gft-summary span {
  color: inherit;
  order: 1;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}
/* span.summaryServices p:nth-child(even) {
  background-color: #ffffff;
} */

span.summaryItem {
  font-size: 0.75rem !important;
}
p.summaryEdit {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
}
.summaryRemove svg {
  order: 1;
}
.summaryRemove span {
  order: 2;
  text-transform: uppercase;
  font-size: 0.6rem;
  margin-top: 3px;
}

/* .gft-embed .summary-check {} */
.gft-embed .summary-check + label {
  cursor: pointer;
}

.gft-embed .summary-check + label div {
  position: absolute;
  left: 0;
  top: 40%;
  height: 22px;
  width: 22px;
  border: 2px solid #fff;
  transform: translate(0, -50%);
  border-radius: 3px;
  transition: 0.2s ease all;
}
.gft-embed .summary-check:disabled + label div,
.gft-embed .summary-check:disabled + label:hover div {
  position: absolute;
  left: 0;
  top: 50%;
  height: 22px;
  width: 22px;
  border: 2px solid #333;
  transform: translate(0, -50%);
  border-radius: 3px;
  transition: 0.2s ease all;
  cursor: default !important;
}

.gft-embed .summary-check:checked:disabled + label div,
.gft-embed .summary-check:checked:disabled + label:hover div {
  position: absolute;
  left: 0;
  top: 50%;
  height: 22px;
  width: 22px;
  border: 2px solid #a0a0a0;
  transform: translate(0, -50%);
  border-radius: 3px;
  transition: 0.2s ease all;
  cursor: default !important;
}

.gft-embed .summary-check:disabled + label span a,
.gft-embed .summary-check:disabled + label span,
.gft-embed .summary-check:disabled + label {
  color: #333 !important;
  cursor: default !important;
}

.gft-embed .summary-check + label span {
  padding-left: 35px;
}
.gft-embed .summary-check:checked + label div,
.gft-embed .summary-check + label:hover div {
  border: 2px solid var(--brandColor);
}
.gft-embed .summary-check + label div:before {
  content: "";
  font-size: 2rem;
  line-height: 0;
  display: block;
  /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9IiNiZjBkM2UiIGQ9Ik0zNzgtMjIyIDEzMC00NzBsNjgtNjggMTgwIDE4MCAzODMtMzgzIDY4IDY4LTQ1MSA0NTFaIi8+PC9zdmc+); */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iNDgiPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0zNzgtMjQwLjI2MSAxNDguMjYxLTQ3MGw0OC45NzgtNDguOTc4TDM3OC0zMzguMjE4bDM4My43NjEtMzgzLjc2TDgxMC43MzktNjczIDM3OC0yNDAuMjYxWiIvPjwvc3ZnPg==);
  background-size: cover;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 35px;
  width: 35px;
  color: #fff;
  opacity: 0;
  transition: 0.1s ease-out;
}
.gft-embed .summary-check:checked + label div:before {
  opacity: 1;
}

/* Guest Manage Modal */

#manageGuestModal.modal {
  background: #00000060;
}
#manageGuestModal .modal-content {
  background-color: #1c1c1c;
  border: none;
}
#manageGuestModal .modal-header {
  border: none;
  padding: 1.5rem;
}
#manageGuestModal .modal-body {
  padding: 1rem 1.5rem;
}
#manageGuestModal .modal-footer {
  border: none;
  padding: 1rem;
  padding-top: 2rem;
}

/* Temp Tabs */

.gft-nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}
.gft-nav .nav-tabs {
  border-bottom: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  background: #0a0a0a;
}
.gft-nav .nav-tabs button {
  color: var(--brandColor);
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 800;
  white-space: nowrap;
}

/* Mobile Styling */
@media screen and (max-width: 576px) {
  .gft-view-desc {
    font-size: 16px;
  }
  .input-wrap {
    margin-top: 10px;
  }
  .input-wrap:first-of-type {
    margin-top: 0;
  }
  .gft-embed label {
    font-size: 1rem;
  }
  .gft-embed input {
    font-size: 0.8rem;
    padding: 7px;
    height: 35px;
  }
  .gft-input + label:after {
    font-size: 1rem;
    padding-left: 1px;
    bottom: -10px;
    height: 25px;
    width: 25px;
  }
  .gft-input:not(:checked):not(:disabled) + label:hover:not(.guest-add) {
    background: #fff;
    color: #000;
  }
  /* .gft-guest-wrapper {
    grid-template-columns: repeat(auto-fill, 30%);
  } */
  .gft-input {
    font-size: 0.7rem;
    padding: 5px;
  }
  .gft-input + label.guest-add .icon,
  .gft-input + label.guest-add:hover .icon {
    height: 2rem;
    width: 2rem;
    margin-bottom: 0;
  }
  .gft-input + label.guest-add span:not(.icon) {
    opacity: 1;
  }
  .guest-add {
    font-size: 0.45rem;
  }
  .gft-embed .gft-service {
    padding: 7px;
  }
  .gft-embed .gft-service h4 {
    font-size: 1rem;
    line-height: 1;
  }
  .gft-embed .gft-service p {
    font-size: 0.5rem;
  }
  .gft-embed .gft-service h3 {
    margin-left: 7px;
  }

  .span.small-text {
    font-size: 0.75rem;
    color: #000;
  }

  .gft-info p,
  .small-text {
    font-size: 0.65rem !important;
    margin-top: 6px;
    color: #fff;
  }
}

@media screen and (max-width: 991px) {
  .gft-embed .gft-service h4 {
    font-size: 1rem;
  }

  .gft-embed .gft-service p {
    font-size: 0.87499rem;
  }
}

.relative {
  position: relative;
}
.phone-input,
.phone-input li {
  color: #000 !important;
}

.gft-modal-portal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
}

.gft-modal-content {
  padding-top: 15px;
  background: var(--gft-bg-color);
  border: 4px solid var(--gft-bg-color);
  border-radius: 4px;
  max-width: 650px;
  position: unset;
  z-index: 10001;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid #444;
  border-radius: 10px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .gft-modal-content {
    max-width: calc(100% - 20px) !important;
  }
}
.gft-modal-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 20px;
}

.gft-modal-overlay {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Rubik-Fixed";
  position: relative;
}
.gft-modal-overlay:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.gft-modal-content.loading {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0px;
}

/* One time passcode OTP */
.remember-me {
  text-transform: initial;
}

.textAdjust-200 {
  word-spacing: 0.5px !important;
  letter-spacing: 0.5px !important;
  font-weight: 300 !important;
  line-height: 20px !important;
}

.textAdjust-300 {
  word-spacing: 0.25px !important;
  letter-spacing: 0.25px !important;
  font-weight: 200 !important;
  line-height: 18px !important;
}

.gft-embed .gft-phone-input-enable,
.gft-embed .gft-phone-input-enable:focus,
.gft-embed .gft-phone-input-enable:active,
.gft-embed .gft-phone-input-enable:-webkit-autofill::first-line,
.gft-embed .gft-phone-input-enable:-webkit-autofill,
.gft-embed .gft-phone-input-enable:-webkit-autofill:hover,
.gft-embed .gft-phone-input-enable:-webkit-autofill:focus,
.gft-embed .gft-phone-input-enable:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.gft-embed .gft-phone-input-enable:disabled,
.gft-embed .gft-phone-input-enable:focus:disabled,
.gft-embed .gft-phone-input-enable:active:disabled,
.gft-embed .gft-phone-input-enable:-webkit-autofill:disabled,
.gft-embed .gft-phone-input-enable:-webkit-autofill:hover:disabled,
.gft-embed .gft-phone-input-enable:-webkit-autofill:focus:disabled,
.gft-embed .gft-phone-input-enable:-webkit-autofill:active:disabled {
  -webkit-box-shadow: 0 0 0 40px rgba(24, 24, 24, 0.9) inset !important;
  -webkit-text-fill-color: #444 !important;
  -webkit-text-stroke-color: #444 !important;
  border-color: rgba(24, 24, 24, 0.8) !important;
  border-radius: 1px !important;
}

.gft-embed input.otp-input-container {
  justify-content: flex-start !important;
  align-items: center !important;
}

.gft-embed input.otp-input {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  width: 10px;
  height: 40px;
  text-align: center;
  border: 0px solid #999;
  border-radius: 5px;
  font-size: 20px;
  padding: 2px;
  margin: 0px;
  margin-left: 5px;
}

.gft-embed .otp {
  width: 35px;
  height: 35px;
  border-radius: 5px !important;
}
@media screen and (min-width: 768px) {
  .gft-embed .otp {
    width: 45px;
    height: 45px;
    border-radius: 5px !important;
  }
}

.gft-embed .otp:nth-child(4) {
  margin-left: 0.35rem;
}

.gft-embed .otp:focus {
  border: var(--bs-border-width) solid var(--brandColor);
  box-shadow: 0 0 10px 0.4px var(--brandColor) !important;
}
.gft-embed .otp:disabled {
  box-shadow: 0 0 0 40px rgba(24, 24, 24, 0.9) inset !important;
  -webkit-text-fill-color: #444 !important;
  -webkit-text-stroke-color: #444 !important;
  border-color: rgba(75, 74, 74, 1) !important;
}
.capAplha {
  text-transform: capitalize;
}
.capAplha:disabled {
  box-shadow: 0 0 0 40px rgba(24, 24, 24, 0.678) inset !important;
  -webkit-text-fill-color: #444 !important;
  -webkit-text-stroke-color: #444 !important;
  border-color: rgba(24, 24, 24, 0.3) !important;
  border-radius: 1px !important;
  border-width: 1px !important;
  border-style: solid;
  color: #495057;
}

.noBg {
  background-color: rgba(0, 0, 0, 0);
}

.serviceHeading {
  /* border-bottom: 2px solid var(--brandColor); */
  text-transform: capitalize;
}

.serviceHeadingWaitTime {
  /* border-bottom: 2px solid var(--brandColor); */
  text-transform: capitalize;
  font-weight: 100px !important;
  font-size: 16px !important;
}

.serviceHeading h5,
.gft-summary h5 {
  font-family: "big-noodle";
  line-height: 80%;
  font-size: 2rem;
  position: relative;
  display: block !important;
  margin-bottom: 0;
}
.serviceHeading h5:after,
.gft-summary h5:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 4px;
  width: 15px;
  margin-left: 5px;
  background-color: var(--brandColor);
}
.serviceHeadingRemove {
  text-transform: capitalize;
  color: #fff !important;
  border: 0px solid var(--brandColor) !important;
  padding: 0px 5px 0px 5px;
  max-width: fit-content !important;
  font-size: 14px;
}

.btn-back {
  --bs-btn-font-weight: 600 !important;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: transparent;
  --bs-btn-hover-color: var(--brandColor);
  --bs-btn-hover-bg: transparent;
  --btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--brandColor);
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-font-size: 32px;
}
.btn-back:hover {
  color: #fff;
}

.btn-primary {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--brandColor);
  --bs-btn-border-color: none;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--brandColor);
  --bs-btn-hover-border-color: var(--brandColor);
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--brandColor);
  --bs-btn-active-border-color: var(--brandColor);
  --bs-btn-line-height: 75%;
  --bs-btn-border-radius: 2px;
  --bs-btn-font-size: 2.5rem;
  --bs-btn-font-family: big-noodle;
  --bs-btn-font-style: normal;
  --bs-btn-white-space: nowrap;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--brandColor);
  --bs-btn-disabled-border-color: var(--brandColor);
  --bs-btn-border-width: 1px;
  text-transform: uppercase;
  min-width: max-content !important;
  white-space: nowrap !important;
  align-items: center !important;
  height: max-content !important;
  min-height: 45px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-secondary {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #fff;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: transparent;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: #fff;
  --bs-btn-line-height: 75%;
  --bs-btn-border-radius: 2px;
  --bs-btn-font-size: 2.5rem;
  --bs-btn-font-family: big-noodle;
  --bs-btn-font-style: normal;
  --bs-btn-white-space: nowrap;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-btn-border-width: 0px;
  text-transform: uppercase;
  min-width: max-content !important;
  white-space: nowrap !important;
  align-items: center !important;
  height: max-content !important;
  min-height: 45px !important;
}

.arrowButtonRight {
  transform: rotate(90deg);
  background-image: var(--arrow);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  filter: brightness(0) invert(1);
  height: 45px !important;
}

.arrowButtonLeft {
  background-image: var(--arrow);
  transform: rotate(270deg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  filter: brightness(0) invert(1);
  height: 45px !important;
}

.contentWrapper {
  overflow-x: hidden;
  overflow-y: scroll !important;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.badge {
  font-size: 0.95em !important;
}

.text-bg-danger {
  background-color: var(--brandColor) !important;
}

.mobileText {
  font-size: 0.75em !important;
}
